<template>
    <button class="loaderBtn" type="submit" disabled>
        <div class="item loading-6">
            <svg viewBox="25 25 50 50">
                <circle cx="50" cy="50" r="20" :style="`stroke:${stroke};`"></circle>
            </svg>
        </div>
    </button>
</template>

<script>
export default {
    data() {
        return {
        };
    },
    props: {
        stroke: {
            type: String,
            required:false,
            default: "#fff",
            description: "stroke of loader"
        },
    },
    components:{

    },
    computed:{

    },
    watch:{

    },
    methods:{

    },
    beforeMount(){

    },
}
</script>

<style>
    .loaderBtn .item.loading-6 svg{
        width:20px;
    }
    .loaderBtn .item.loading-6 svg circle{
        fill: none;
        stroke: #fff;
        stroke-width: 5;
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }
</style>